import { Controller } from '@hotwired/stimulus';
import { set } from 'immutable';

// Connects to data-controller="search"
export default class extends Controller {
  static targets = ['input', 'results'];

  connect() {}

  search() {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.element.requestSubmit();
      this.resultsTarget.innerHTML =
        '<p class="text-center text-muted">🔎 Searching...</p>';
      this.toggleResults();
    }, 150);
  }

  toggleResults() {
    if (this.inputTarget.value) {
      this.showResults();
    } else {
      this.hideResults();
    }
  }

  showResults() {
    const resultsMenu = this.resultsTarget.parentNode;
    resultsMenu.style.display = 'block';
  }

  hideResults() {
    const resultsMenu = this.resultsTarget.parentNode;
    setTimeout(() => {
      resultsMenu.style.display = 'none';
    }, 100); // 100ms timeout is needed so the user can click a result in the menu
  }

  showPlaceholderOnBlur() {
    setTimeout(() => {
      this.showPlaceholder();
    }, 100); // 100ms timeout is needed to give the menu time to hide
  }

  showPlaceholder() {
    const input = this.inputTarget;
    const resultsMenu = this.resultsTarget.parentNode;

    if (resultsMenu.style.display === 'block') {
      return;
    }

    localStorage.setItem('pnp_search_q', input.value);

    input.value = null;
  }

  showLastSearch() {
    const input = this.inputTarget;
    const lastSearch = localStorage.getItem('pnp_search_q');

    if (lastSearch) {
      input.value = lastSearch;
    }
  }
}
