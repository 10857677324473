document.addEventListener('turbo:load', function () {
  $('[data-behavior~=sortable]').each(function () {
    var $th = $(this),
      thIndex = $th.index(),
      inverse = false;
    $th.on('click', function () {
      $(this)
        .parents('table')
        .find('td')
        .filter(function () {
          return $(this).index() === thIndex;
        })
        .sortElements(
          function (a, b) {
            if ($.text([a]).match(/^\$/)) {
              a = $.text([a]).substring(1);
              b = $.text([b]).substring(1);
            }
            if (!isNaN(a) && !isNaN(b)) {
              return parseInt(a) > parseInt(b)
                ? inverse
                  ? -1
                  : 1
                : inverse
                ? 1
                : -1;
            } else {
              return $.text([a]).toLowerCase() > $.text([b]).toLowerCase()
                ? inverse
                  ? -1
                  : 1
                : inverse
                ? 1
                : -1;
            }
          },
          function () {
            return this.parentNode;
          }
        );
      $(this)
        .parents('table')
        .find('th')
        .removeClass('sorting-desc sorting-asc');
      $(this).addClass(inverse ? 'sorting-desc' : 'sorting-asc');
      inverse = !inverse;
    });
  });
});
