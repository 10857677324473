document.addEventListener('turbo:load', function () {
  if ($('[data-behavior~=instafeed').length) {
    var accessToken =
      'IGQVJWOW1jMkJpVTdsSEozNWdBODNTSk15bnVTN2VGdnB1bW9iU1VoQWptWWlZAb29TR2tGLXFoeldvTG9LbHVpSk1MYXJ2WVo3ZAGZAXZA0xUbGkyREQ2ZA2wzaldMVTVBUWhWTDY2TDJQWENLMFl3bjN4MQZDZD';

    var feed = new Instafeed({
      accessToken: accessToken,
      apiTimeout: 15000,
      error: function (error) {
        $('[data-behavior~=instagram-placeholder]')
          .find('.placeholder-loading')
          .addClass('d-none');
        $('[data-behavior~=instagram-placeholder]')
          .find('.placeholder-error')
          .removeClass('d-none');
        console.error(error);
      },
      get: 'user',
      limit: 12,
      resolution: 'thumbnail',
      target: 'instafeed-content',
      template:
        '\
      <div class="instagram-post {{type}}">\
        <a href="{{link}}" target="_blank">\
          <div class="instagram-crop">\
            <img src="{{image}}", class="img-fluid" />\
          </div>\
        </a>\
      </div>',
      success: function () {
        $('[data-behavior~=instagram-placeholder]').remove();
      },
      userId: 13573317118,
    });

    feed.run();

    $('[data-behavior~=instafeed-error]').on('click', function () {
      $('[data-behavior~=instagram-placeholder]')
        .find('.placeholder-loading')
        .removeClass('d-none');
      $('[data-behavior~=instagram-placeholder]')
        .find('.placeholder-error')
        .addClass('d-none');
      feed.run();
    });

    // auto refresh ig access token
    $.ajax({
      type: 'get',
      url:
        'https://graph.instagram.com/refresh_access_token?grant_type=ig_refresh_token&access_token=' +
        accessToken,
    });
  }
});
