document.addEventListener('turbo:load', function () {
  if ($('body.cart.show').length) {
    $('form').on('submit', function () {
      let $btn = $('[data-behavior~=checkout-btn]', this);
      let width = $btn.outerWidth();
      $btn.hide();
      $(this)
        .find('.submit-box')
        .addClass('btn btn-primary')
        .css('width', `${width}px`)
        .append(
          '<div class="spinner-grow spinner-grow-sm" role="status"><span class="sr-only">Loading...</span></div>'
        );
    });
  }
});
