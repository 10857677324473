document.addEventListener('turbo:load', function () {
  if ($('body.podcast').length) {
    const fetchPath = $('[data-podcasts-path]').data('podcasts-path');
    $.ajax(fetchPath, { credentials: 'include' })
      .then(function (response) {
        return response;
      })
      .then(function (podcasts) {
        let newestPublicVideos = [];
        $.each(podcasts, function (_index, podcast) {
          if (podcast.snippet.description != 'This video is private.') {
            newestPublicVideos.push(podcast);
          }
        });

        const $podcastPlaceholder = $('[data-behavior~=podcast-placeholder]'),
          latestPodcastVideoId =
            newestPublicVideos[0].snippet.resource_id.video_id,
          latestPodcastEmbedUrl = `https://www.youtube-nocookie.com/embed/${latestPodcastVideoId}`,
          latestPodcastTitle = newestPublicVideos[0].snippet.title,
          latestPodcastDescription = newestPublicVideos[0].snippet.description,
          latestPodcastHtml = `
            <div class="row podcast m-0">
              <div class="col-12 p-0">
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="${latestPodcastEmbedUrl}" allowfullscreen></iframe>
                </div>
              </div>
              <div class="col-12 px-4 pt-4 pb-3">
                  <h4 class="line-clamp-2" title="${latestPodcastTitle}">
                  <a href="https://youtube.com/watch?v=${latestPodcastVideoId}" target="_blank">${latestPodcastTitle}</a>
                  </h4>
                  <p class="line-clamp-4 mb-0">${latestPodcastDescription}</p>
                </div>
            </div>
          `;

        $podcastPlaceholder
          .removeClass('loading')
          .empty()
          .append(latestPodcastHtml);

        const $podcastFeedPlaceholder = $(
            '[data-behavior~=podcast-feed-placeholder]'
          ),
          $podcastFeedContent = $('[data-behavior~=podcast-feed-content]');

        newestPublicVideos.forEach((podcast, index) => {
          if (podcast == newestPublicVideos[0]) {
            return;
          }

          const podcastVideoId = podcast.snippet.resource_id.video_id,
            podcastEmbedUrl = `https://www.youtube-nocookie.com/embed/${podcastVideoId}`,
            podcastTitle = podcast.snippet.title,
            podcastDescription = podcast.snippet.description,
            podcastHTML = `
              <div class="row podcast">
                <div class="col-12 col-lg-5 col-xl-4 p-0">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe class="embed-responsive-item" src="${podcastEmbedUrl}" allowfullscreen></iframe>
                  </div>
                </div>
                <div class="col-12 col-lg-7 col-xl-8 px-4 pt-4 pb-3">
                  <h4 class="line-clamp-2" title="${podcastTitle}">
                  <a href="https://youtube.com/watch?v=${podcastVideoId}" target="_blank">${podcastTitle}</a>
                  </h4>
                  <p class="line-clamp-4 mb-0">${podcastDescription}</p>
                </div>
              </div>
            `;

          $podcastFeedContent.append(podcastHTML);
        });

        $podcastFeedPlaceholder
          .removeClass('loading')
          .find('.placeholder-loading')
          .addClass('d-none');

        $podcastFeedContent.removeClass('d-none');
      })
      .fail(function (error) {
        console.error(error);

        const $podcastPlaceholder = $('[data-behavior~=podcast-placeholder]'),
          $podcastFeedPlaceholder = $(
            '[data-behavior~=podcast-feed-placeholder]'
          );

        $podcastPlaceholder
          .find('.placeholder-error')
          .removeClass('d-none')
          .end()
          .find('.placeholder-loading')
          .addClass('d-none');

        $podcastFeedPlaceholder
          .find('.placeholder-error')
          .removeClass('d-none')
          .end()
          .find('.placeholder-loading')
          .addClass('d-none');
      });
  }
});
