document.addEventListener('turbo:load', function () {
  if ($('body.pnpbe.posts').length) {
    $('[data-behavior~=select]').select2({
      allowClear: false,
      closeOnSelect: false,
      placeholder: 'Select Categories',
    });

    $('[data-behavior~=select]').on(
      'select2:opening select2:closing',
      function (event) {
        var $searchfield = $(this).parent().find('.select2-search__field');
        $searchfield.prop('disabled', true);
      }
    );

    $('[data-behavior~=post-cover-image]').on('change', function () {
      var fileName =
        $(this).val() == ''
          ? 'Upload cover image'
          : $(this).val().split('\\').pop();
      $(this).next().text(fileName);
    });
  }
});
