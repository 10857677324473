document.addEventListener('turbo:load', function () {
  if ($('[data-behavior~=upload-image').length) {
    function previewImage(input) {
      if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
          $('[data-behavior~=upload-image').attr('src', e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
      }
    }

    if ($('[data-behavior~=img-upload-input').length) {
      $('[data-behavior~=img-upload-input').on('change', function () {
        previewImage(this);
      });
    }
  }
});
