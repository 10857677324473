// Entry point for the build script in your package.json

import Rails from '@rails/ujs';
//import Turbolinks from "turbolinks"
import * as ActiveStorage from '@rails/activestorage';

Rails.start();
ActiveStorage.start();

import '@hotwired/turbo-rails';
import './controllers';

require('trix');
require('@rails/actiontext');

require('./packs/vendor/buy_me_a_coffee.js');
require('./packs/vendor/sort_elements.js');

require('./packs/modules/fetch_content.js');
require('./packs/modules/footer-height.js');
require('./packs/modules/instafeed-config.js');
require('./packs/modules/local_auto_save.js');
require('./packs/modules/navbar.js');
require('./packs/modules/preview_image.js');
require('./packs/modules/sortable.js');
require('./packs/modules/trix-editor-overrides.js');
require('./packs/modules/youtube_embed.js');

require('./packs/pages/pnp_be/posts.js');
require('./packs/pages/pnp_be/products.js');

require('./packs/pages/cart.js');
require('./packs/pages/contact.js');
require('./packs/pages/podcast.js');
