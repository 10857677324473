document.addEventListener('turbo:load', function () {
  if ($('body.pnpbe.products').length) {
    $(window).on('pnp:fetch', function () {
      let product_count = $(
        '[data-behavior~=products-table] tbody tr:not(".empty")'
      ).length;
      $('[data-behavior~=product-count]').text(`(${product_count})`);
      $('[data-behavior~=loading-text]').remove();
    });
  }

  if ($('body.pnpbe.downloadables').length) {
    $('[data-behavior~=select]').select2({
      allowClear: true,
      closeOnSelect: true,
      placeholder: {
        id: '-1',
        text: 'Select Product for this File',
        selected: 'selected',
      },
      theme: 'bootstrap4',
    });

    document
      .querySelector('[data-behavior~=file-input]')
      .addEventListener('change', function (event) {
        var fileName = $(this).val().split('\\').pop();
        var $label = $(this).parent().find('label');
        $label.addClass('selected');
        $label.text(fileName);

        if (!$('[data-behavior~=file-name]').val()) {
          var displayName = fileName.substring(0, fileName.lastIndexOf('.'));
          $('[data-behavior~=file-name]').val(displayName + ' ebook');
        }
      });
  }
});
