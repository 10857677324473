document.addEventListener('turbo:load', function () {
  function initBehaviors(parentElement) {
    // Fetch content
    $(parentElement)
      .find('[data-behavior~=fetch]')
      .each(function () {
        var that = this;
        $.ajax(that.dataset.path, { credentials: 'include' })
          .then(function (response) {
            return response;
          })
          .then(function (html) {
            $(that).html(html);
            $(that).trigger('pnp:fetch');
            initBehaviors(that);
          })
          .fail(function (error) {
            $(that)
              .find($('[data-behavior~=loading-text]'))
              .text(`Error ${error.status}.`)
              .addClass('text-danger')
              .find('i')
              .removeClass('fa-sync fa-spin')
              .addClass('fa-exclamation-triangle');

            console.error(error);
          });
      });
  }
  initBehaviors(document.querySelector('body'));
});
