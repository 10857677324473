document.addEventListener('turbo:load', function () {
  if ($('body.contact.index').length) {
    var forms = document.getElementsByClassName('needs-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener(
        'submit',
        function (event) {
          if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            $('input[type=submit]', this).val('Send').attr('disabled', false);
          } else {
            $('input[type=submit]', this)
              .val('Sending...')
              .attr('disabled', 'disabled');
          }
          form.classList.add('was-validated');
        },
        false
      );
    });
  }
});
