window.addEventListener('trix-file-accept', function (event) {
  const acceptedTypes = ['image/jpeg', 'image/png'];
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault();
    alert('Attachment must be jpeg or png!');
  }
});

document.addEventListener('turbo:load', function () {
  $('figure img').each(function () {
    var src = $(this).attr('src');
    if (src.includes('store.poodlesandparrots.com')) {
      src = src.replace('store.poodlesandparrots.com', 'poodlesandparrots.com');
      $(this).attr('src', src);
    }
  });
});
