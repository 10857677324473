document.addEventListener('turbo:load', function () {
  function updateFooterHeight() {
    var footerHeight = Math.floor($('[data-behavior~=footer]').outerHeight());
    $('[data-behavior~=body]').css('padding-bottom', footerHeight);
    $('[data-behavior~=footer]').css('min-height', footerHeight);
    console.log(footerHeight);

    if ($('.convertkit').length && $('.convertkit form').length < 1) {
      setTimeout(() => {
        updateFooterHeight();
      }, 100);
    }
  }

  updateFooterHeight();
});
