document.addEventListener('turbo:load', function () {
  const $navbar = $('.navbar');

  $(window).on('scroll', function () {
    if ($navbar.offset().top > $(window).height() / 7) {
      $navbar.addClass('mini');
    } else if ($navbar.offset().top < $(window).height() / 9) {
      $navbar.removeClass('mini');
    }
  });
});
